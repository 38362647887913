@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Roboto:wght@100;500&family=Saira:wght@400;700&display=swap");

ol {
  list-style: none;
  counter-reset: counter;
  margin-left: 0;
  padding-inline-start: 0;
}

ol li {
  counter-increment: counter;
  font-family: "Saira";
  font-weight: 700;
  font-size: 20px;
  color: black;
  margin-bottom: 20px;
  width: 100%;
}

ol li::before {
  content: counter(counter);
  margin-bottom: 10px;
  width: 50px;
  height: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  background-color: #80221c;
  border-radius: 15px;
  color: #fff;
  margin-right: 30px;
}

h4 {
  color: #80221c;
  font-family: Saira;
}

a:link {
  color: #0029ff;
}

a:visited {
  color: #0029ff;
}

#formButton {
  text-align: center;
  margin-bottom: 50px;
  line-height: 16px;
  margin: 20px;
}

button {
  padding: 9px 16px;
  background-color: #80221c;
  color: white;
  border: 0;
  border-radius: 8px;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
}

.contactUs {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 50px;
  justify-content: center;
  text-align: center;
}

.FAQ {
  margin-bottom: 30px;
}

.FAQHeader {
  color: black;
  font-size: 40px;
  font-family: "Saira";
  font-weight: 700;
  margin-bottom: 20px;
}

.FAQContent {
  color: black;
  font-family: "Saira";
}

.FAQQuestion {
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 10px;
}

.FAQAnswer {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}

.disclaimer {
  padding-bottom: 50px;
  color: black;
  font-family: "Saira";
  font-weight: 400;
}

.title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 1000px) {
  .Minter {
    max-width: 100vh;
    max-height: 80vh;
    padding: 60px 60px;
    text-align: left;
  }

  .content {
    color: #8c1515;
    display: flex;
    flex-direction: column;
    align-items: center;
    letter-spacing: 0.12em;
  }

  .listContent {
    margin-top: 20px;
    font-weight: 400;
  }

  .square {
    width: 45vh;
    height: 45vh;
    background-color: #b83a4b;
  }

  table {
    margin: 0px auto;
  }

  h1 {
    font-size: 15vh;
    text-align: center;
    font-family: "Bebas Neue";
    margin-top: 0;
    margin-bottom: 0;
  }

  .Instructions {
    font-family: "Saira";
    color: black;
  }
}

@media only screen and (min-width: 1000px) {
  body {
    margin: 0;
    color: #8c1515;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    font-family: "Source Sans Pro", monospace;
  }

  .listContent {
    margin-left: 80px;
    font-weight: 400;
  }

  .Minter {
    max-width: 100vh;
    max-height: 80vh;
    padding: 60px 60px;
    margin: 0 auto;
    text-align: left;
  }

  .instructions {
    margin-bottom: 50px;
  }

  .content {
    display: flex;
    flex-direction: row;
    justify-content: center;

    align-items: center;
    letter-spacing: 0.12em;
  }

  .title {
    margin-bottom: 100px;
  }

  h1 {
    font-size: 15vh;
    text-align: center;
    font-family: "Bebas Neue";
    display: inline-block;
    align-items: center;
    text-align: center;
    letter-spacing: 0.3em;
  }

  .Instructionsheader {
    margin-bottom: 60px;
    text-align: center;
  }

  .square {
    width: 45vh;
    height: 45vh;
    background-color: #b83a4b;
  }

  h1 {
    font-size: 10vh;
    margin: 0;
  }

  h2 {
    color: black;
    font-size: 30px;
    font-family: "Saira";
  }

  h3 {
    font-size: 24px;
    font-family: "Saira";
    margin-bottom: 10px;
  }

  p {
    font-size: 18px;
    font-family: "Saira";
    color: black;
  }

  #status {
    color: red;
  }

  .uploadFile {
    border: 4px dashed rgba(4, 4, 5, 0.1);
    border-radius: 16px;
    text-align: center;
    padding: 32px 60px;
  }

  #header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
